import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import QuotePicker from "../components/QuotePicker/QuotePicker";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Message,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import { SocialIcon } from "react-social-icons";
import logo from "../images/logo-name-insurance.png";
import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const HomepageHeading = () => (
  <Container  className="masthead__body">
    <Grid stackable columns={2} relaxed="very">
      <Grid.Column>
        <div    style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
          <Image  src={logo} />
        </div>
      </Grid.Column>
      <Grid.Column>
        <div style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>  
          <h2 style={{marginTop: "8px"}}>
            <a style={{color: "white"}} href="https://www.google.com/maps/place/1653+McFarland+Rd,+Pittsburgh,+PA+15216/@40.394406,-80.046807,13z/data=!4m5!3m4!1s0x8834f70ec15a92e7:0x845976f1bc9bdd75!8m2!3d40.3944059!4d-80.0468068?hl=en-US">
              1653 McFarland Road<br />
              Pittsburgh, PA  15216<br />
            </a>
          </h2>
          <h2 style={{marginTop: "0"}}>
            Phone: 412-922-8222<br />
            Email: <a href="mailto:info@hurstweiss.com" style={{color: "white", textDecorationLine: "underline"}}>info@hurstweiss.com</a>
            <br />
          </h2>
          <div style={{marginBottom: "8px"}}>
            <SocialIcon url="https://www.facebook.com/hurstweiss" fgColor="white"/>
            <SocialIcon url="https://twitter.com/hurstweissins" fgColor="white" style={{margin: "0 12px"}}/>
            <SocialIcon url="https://www.linkedin.com/company/hurst-weiss-insurance-&-investment-services" fgColor="white" />
          </div>     
        </div>
      </Grid.Column>
    </Grid>
  </Container>
);

const IndexPage = (props: LayoutProps) => {
  const [banner, setBanner] = React.useState(null)
  React.useEffect(() => {
    const client = createClient({
      space: "2l5gu6vk572p",
      accessToken: "gIs5GVfdLBDkUwa-KS9cONG4xbKLwCuMTRI6cyCQX74"
    });
    
    client
    .getEntry("4lJqO667X3xONsr9XJUGYQ")
    .then(entry => {
      if (entry.fields.title) {
        setBanner({
          title: entry.fields.title,
          body: entry.fields.body
        })
        debugger
      }
    })
  }, []);

  return <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <HomepageHeading />
    </Segment>
    <Segment style={{ padding: "4em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          {banner && banner.title && banner.body && <Message >
          <Message.Header>{banner.title}</Message.Header>
            {documentToReactComponents(banner.body)}
          </Message>}
          <Grid.Column width={8}> 
            <Header as="h3" style={{ fontSize: "2em" }}>
              About Us
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Hurst-Weiss Insurance has been serving the insurance needs of businesses and families in Pittsburgh & Western Pennsylvania since 1915. One of the keys to our success is that we act as an advisor to our clients not merely a salesperson. We will discuss options, costs and exposures without high-pressure sales tactics. Please take advantage of our knowledge and ability to explain insurance coverages in easy to understand terms.
            </p>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Our Website
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Our website is designed to help educate individuals and businesses on the sometimes confusing aspects of home, auto, business, health & life insurance and offers you the ability to work directly with an agent or on your own. 
              If you are looking for an insurance agency that will work with you as a consultant and help you understand the many factors that need to be considered when designing an insurance program, we can help you. Remember that making a decision on insurance coverage based solely on price can result in costing you thousands of dollars when you have a claim. One of the most important benefits of having an independent insurance agent provide insurance coverage for you is that we are in the best position to fight for you when you have a claim.
            </p>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
          <div className="mapouter"><div className="gmap_canvas"><iframe width={400} height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=1653%20McFarland%20Road%20Pittsburgh%2C%20PA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.embedgooglemap.net" /></div><style dangerouslySetInnerHTML={{__html: ".mapouter{text-align:right;height:400px;width:400px;}.gmap_canvas {overflow:hidden;background:none!important;height:400px;width:400px;}" }} /></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Link to="/insurance">
              <Button size="huge">Get a Quote</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: "0em" }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "Great prompt friendly service!"
            </Header>
            <p style={{ fontSize: "1.33em" }}>Great prompt friendly service! A staff that is very helpful. I can’t say enough…it is a pleasure dealing with you.</p>
            <p>Barb Leson, Gibsonia, PA</p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "It is truly a GREAT Company."
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              I have been buying insurance from Hurst-Weiss for many years. It is truly a GREAT Company. Cathy Namisnak has been indispensable in assisting me at various times, she is truly competent, professional and very helpful as all of the other employees are at Hurst-Weiss are. KEEP UP THE GOOD WORK
            </p>
            <p>
              Edward Putch, Pittsburgh, PA 
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: "8em 0em" }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Why Should I Buy A Renter’s Insurance Policy?
        </Header>
        <p style={{ fontSize: "1.33em" }}>
        A renter’s insurance policy provides a great deal of value & protection for the price to individuals and families.
        </p>
        <Link to="/blog/2019-01-26--why-should-i-buy-a-renter-s-insurance-policy/">
          <Button as="a" size="large">
            Read More
          </Button>
        </Link>
        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#">Case Studies</a>
        </Divider>

        <Header as="h3" style={{ fontSize: "2em" }}>
          How Insurance Companies Use Credit Scores
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          For years insurance companies have been using credit-based insurance scores to determine the premiums that consumers pay for insurance policies. When you apply for insurance, the insurance company will get your credit information from one of the three major US credit bureaus. Your information is entered into a computer program designed by the insurance company to determine your insurance score. Most carriers look at your payment history, credit utilization, collections and bankruptcies. If you pay your bills on time you will have a better score than someone who is always late. People who carry high credit card balances will also have a lower insurance credit score. 
        </p>
        <Link to="/blog/2019-01-27--how-insurance-companies-use-credits-scores/">
          <Button as="a" size="large">
            Continue Reading
          </Button>
        </Link>
      </Container>
    </Segment>
  </div>;
}
export default withLayout(IndexPage);
